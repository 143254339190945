<template>
  <v-row id="product-form-page">
    <v-col cols="12">
        <v-card>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveProduct">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="col-lg-4 col-md-4">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Type</v-subheader>
                        <v-select v-model="model.type" outlined hide-details="auto" :items="['arch','chasis','tire']"
                                  class="text-capitalize" placeholder="Type"></v-select>
                      </v-col>
                      <v-col cols="12 col-lg-4 col-md-4">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Name</v-subheader>
                            <v-text-field  ref="name" v-model="model.name" :error-messages="$helpers.errorMsg('name', $v.model.name, 'Name')"
                                placeholder="Name" outlined @input="$v.model.name.$touch()"  @blur="$v.model.name.$touch()" required/>
                        </v-col>
                        <v-col cols="12 col-lg-4 col-md-4">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Price</v-subheader>
                            <v-text-field v-model.number="model.price" append-icon="mdi-currency-usd" placeholder="Price"
                                :error-messages="$helpers.errorMsg('number', $v.model.price, 'Price')"
                                @input="$v.model.name.$touch()"  @blur="$v.model.name.$touch()"
                                outlined ref="price" type="number" hide-details="auto" required/>
                        </v-col>
                    </v-row>
                    <div class="d-block d-lg-flex justify-end">
                        <v-btn type="submit" color="primary"  class="mr-4">
                            Submit
                        </v-btn>
                        <v-btn type="button" outlined color="primary" class="mr-4 button" @click.native="$router.push('/products')">
                            Cancel
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { minValue, required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';

export default {
    data: (vm) => ({
        productId: vm.$route.params.productId,
        show: false,
        model: {
            name: '',
            type: null,
            price: null
        }
    }),
    validations() {
       return {
            model: {
                name: { required},
                price: { required, minValue: minValue(1)},
            }
        }
    },
    created() {
        if (this.productId != 'create') {
          this.getSelectedProduct(this.productId).then(response => this.model = response);
        }
    },
    methods: {
        ...mapActions(['getSelectedProduct', 'createProduct', 'updateSelectedProduct']),
        saveProduct() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            if (this.productId != 'create') {
                this.updateSelectedProduct(this.model).then(resp => this.$router.push("/products"));
            } else {
                this.createProduct(this.model).then(resp => this.$router.push("/products"));
            }
        },
    },
};
</script>
